import { Agreement } from "components/Agreement/Agreement";
import { Contacts } from "components/Contacts/Contacts";



export const AgreementPage = () => {
 
    return (
       <div>
         <Agreement/>
         <Contacts/>
       </div>
    );
 };
 